import React, {useEffect, useRef, useState} from 'react';
import {Html5Qrcode} from 'html5-qrcode';
import {Button, ConfigProvider, Modal, Radio, Space, Typography} from 'antd';
import "./App.css"

const {Title} = Typography;

const QRScanner = () => {
    const scannerRef = useRef(null);
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);

    const [isScanning, setIsScanning] = useState(false);
    const [decodedText, setDecodedText] = useState("");
    const [ticketText, setTicketText] = useState("");

    useEffect(() => {
        Html5Qrcode.getCameras().then(deviceCameras => setCameras(deviceCameras)).catch(err => {
            Modal.error({
                title: 'Camera Error',
                content: 'An error occurred while accessing the camera. Please check your device settings.'
            });
            console.error("Error getting cameras: ", err);
        });
    }, []);

    useEffect(() => {
        // Cleanup function to ensure the scanner stops when the component unmounts
        return () => {
            console.log("Component unmounting, attempting to stop scanner.");
            stopScanner();
        };
    }, []);

    useEffect(() => {
        if (decodedText) {
            stopScanner();
            setTicketText(decodedText)
            setDecodedText("")
        }
    }, [decodedText]);

    useEffect(() => {
        if (ticketText) {
        }
    }, [ticketText]);

    const handleDecodedText = (decodedText, decodedResult) => {
        console.log(`Code matched = ${decodedText}`, decodedResult);
        setDecodedText(decodedText);
    };

    const calculateQrBoxSize = (viewfinderWidth, viewfinderHeight) => {
        let minEdgePercentage = 0.8; // 80%
        let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
        let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
        return {
            width: qrboxSize,
            height: qrboxSize
        };
    }

    const startScanner = () => {
        if (!scannerRef.current) {
            scannerRef.current = new Html5Qrcode("qr-reader");
        }
        scannerRef.current.start(selectedCamera, {fps: 10, qrbox: calculateQrBoxSize}, handleDecodedText)
            .then(() => {
                console.log("Scanner started successfully.");
                setIsScanning(true);
            })
            .catch(err => {
                console.error("Problem starting the QR code scanner: ", err);
                setIsScanning(false);
            });
    };

    const stopScanner = () => {
        if (isScanning) {
            scannerRef.current.stop().then(() => {
                console.log("QR Scanning stopped successfully.");
                setIsScanning(false);
            }).catch(err => {
                console.error("Problem stopping the QR code scanner: ", err);
            });
        } else {
            console.log("Stop scanner called, but scanner is not running.");
        }
    };

    const handleStart = () => {
        if (!isScanning) {
            setTicketText(null);
            startScanner();
        } else {
            console.log("Scanner already running.");
        }
    };

    const onCameraChange = e => {
        setSelectedCamera(e.target.value);
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#000',
                },
            }}
        >
            <div className="fullscreen-container">
                <div className="container">
                    <div className="content">
                        <img src="logo192.png" alt="" className="logo"/>
                        <Title level={3}>System biletowy</Title>
                        {!scannerRef.current &&
                            <Radio.Group onChange={onCameraChange}>
                                <Space direction="vertical">
                                    {cameras.map(camera => (
                                        <Radio value={camera.id}>
                                            {camera.label}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        }
                        {ticketText &&
                            <Title level={4}>Odczytany kod: {ticketText}</Title>
                        }
                        {!isScanning &&
                            <Button type="primary" onClick={handleStart} disabled={selectedCamera === null}>
                                Start
                            </Button>
                        }
                        <div className="qr-container">
                            <div id="qr-reader"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default QRScanner;
